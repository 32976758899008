<template>
  <div id="app" :class="{ s_view_scene: ageGateConfirmation }">
    <Symbols />
    <TopBar />

    <BackgroundGlobes />
    <Scene :scale="scale" />
  </div>
</template>

<script>
import Scene from '@/components/Scene';
import TopBar from '@/components/TopBar';
import BackgroundGlobes from '@/components/BackgroundGlobes';
import Symbols from '@/components/SvgSymbols';

export default {
  name: 'App',
  components: {
    Symbols,
    Scene,
    TopBar,
    BackgroundGlobes
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      ageGateConfirmation: true,
      scale: 1
    };
  },
  async mounted() {
    console.info(this.version);
    this.$nextTick(() => {
      window.addEventListener('resize', this.setbackgroundScale);
      this.setbackgroundScale();
    });
  },
  methods: {
    setbackgroundScale() {
      const windowWidth = document.documentElement.clientWidth;
      const windowHeight = document.documentElement.clientHeight;

      const calculatedScale = Math.min(windowWidth / 1600, windowHeight / 900);
      // only scale down?
      // if (calculatedScale > 1.2) calculatedScale = 1.2;
      this.scale = calculatedScale;
    },
    confirmAgeGate() {
      this.ageGateConfirmation = true;
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/app.scss';
#app {
  width: 100%;
  min-height: 100vh;
  background-color: #222222;
  transition: background-color 0.3s ease;
  color: #fff;
}
</style>
