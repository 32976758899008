<template>
  <div class="sb_scene">
    <div
      id="content"
      ref="content"
      class="sb_scene_content"
      style="transform: translate(-50%, -50%) scale(3)"
      :style="{
        transform: 'translate(-50%, -50% ) translateZ(0) scale(' + scale + ')',
      }"
    >
      <div class="sb_scene_input">
        <textarea v-model="prompt" class="prompt-box" type="text"></textarea>
        <label for="seed">
          Seed:
          <input id="seed" v-model="seed" type="number" name="" />
        </label>
      </div>

      <div class="sb_scene_set-bg">
        <div ref="selectBackgroundLabel" class="sb_scene_set-bg_label">
          Select a background:
        </div>
        <div class="sb_scene_set-bg_buttons">
          <button
            v-for="background in backgrounds"
            :key="background.image"
            class="sb_scene_set-bg_btn"
            :class="{ s_active: activeBackground === background.image }"
            @click="setBackground(background)"
          >
            <img :src="background.image" />
          </button>
        </div>
      </div>

      <div class="sb_scene_disclaimer"></div>

      <DragCanvas ref="dragCanvas" class="sb_scene_drag-canvas" />
      <div v-if="error" class="error-message">
        {{ error }}
      </div>

      <div class="sb_scene_buttons">
        <div class="sb_scene_buttons_inner">
          <div class="">
            <ButtonPill
              v-if="!loading"
              button-type="default"
              @click="reset"
              class="sb_scene_buttons_btn"
            >
              Reset
            </ButtonPill>
          </div>
          <div :disabled="loading">
            <ButtonPill
              button-type="default"
              @click="renderImage"
              class="sb_scene_buttons_btn"
            >
              {{ loading ? 'Loading' : 'Render' }}
            </ButtonPill>
          </div>
          <!-- <button v-if="!loading" class="sb_scene_buttons_btn">
            <ButtonPill button-type="default" @click="saveImage">
              Save
            </ButtonPill>
          </button> -->
        </div>
      </div>
      <img
        style="position: absolute; top: 260px; right: 25px"
        :src="renderedImage"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import ButtonPill from '@/components/ButtonPill';
import DragCanvas from '@/components/DragCanvas';
import anime from 'animejs';
import axios from 'axios';

let assets;
// import assets from '@/data/assets.json';

export default {
  name: 'Scene',
  components: {
    ButtonPill,
    DragCanvas,
  },
  props: {
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      error: null,
      prompt: '',
      backgrounds: null,
      bodies: null,
      activeBackground: null,
      activeBodyIndex: 0,
      renderedImage: null,
      loading: false,
      seed: null,
    };
  },
  computed: {
    title() {
      return process.env.VUE_APP_TITLE || 'Create your own scene';
    },
  },
  async mounted() {
    this.prompt = process.env.VUE_APP_STARTING_PROMPT;
    assets = (
      await axios.get(`/data/${process.env.VUE_APP_SET || 'assets'}.json`)
    ).data;
    console.log(assets);
    this.backgrounds = assets.backgrounds;
    this.bodies = assets.bodies;
    window.addEventListener('resize', this.setBannerLineHeight);
    setTimeout(() => {
      this.setBannerLineHeight();
    }, 0);
    this.animateCanvas();
    this.animateSelectBodyLabel(this.$refs.selectBodyLabel);
    this.animateBodyButtons();
    this.animateSelecBackrgoundLabel(this.$refs.selectBackgroundLabel);
    this.animateBackgroundButtons();
    this.animateSceneButtons();
    this.animateDisclaimer();
  },
  methods: {
    async renderImage() {
      if (this.loading) return;
      this.error = null;
      const image = this.$refs.dragCanvas.getImage();
      const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/predictions`;
      const input = {
        image,
        prompt: `${this.prompt}, ${
          process.env.VUE_APP_PROMPT || 'profesional photography'
        }`,
        ...(seed ? {} : { seed: +this.seed }),
      };
      this.loading = true;
      try {
        const { data } = await axios.post(API_URL, input, {
          mode: 'no-cors',
        });
        console.log({
          data,
        });
        this.renderedImage = data[1];
      } catch (e) {
        this.error = e.response.data || e.message || e;
      }
      this.loading = false;
    },
    saveImage() {
      this.$refs.dragCanvas.saveImage();
    },
    setBody(index) {
      this.activeBodyIndex = index;
      this.$refs.dragCanvas.setBody(index);
    },
    setBackground({ image, logoType }) {
      // console.log(image);
      this.activeBackground = image;
      this.$refs.dragCanvas.setBackgroundPattern(image, logoType);
    },
    setBannerLineHeight() {
      const { height } = this.$refs.content.getBoundingClientRect();
      // console.log(height);
      this.bannerLineHeight = height;
      this.bannerLineAddedHeight = 150 * this.scale;
    },
    reset() {
      this.$refs.dragCanvas.reset();
    },
    animateCanvas() {
      anime({
        targets: '.canvas-container',
        opacity: 1,
        scale: [0.8, 1],
        duration: 1200,
        delay: 600,
        easing: 'easeOutElastic(1, .7)',
      });
    },
    animateSelectBodyLabel(elem) {
      anime({
        targets: elem,
        opacity: 1,
        translateY: ['-20px', 0],
        duration: 1200,
        delay: 1700,
        easing: 'easeOutElastic(1, .4)',
      });
    },
    animateBodyButtons() {
      setTimeout(() => {
        anime({
          targets: '.sb_scene_set-body_btn',
          opacity: 1,
          scale: [0, 1],
          duration: 1200,
          easing: 'easeOutElastic(1, .4)',
          delay: anime.stagger(100, {
            start: 400,
          }),
        });
      }, 600);
    },
    animateSelecBackrgoundLabel(elem) {
      anime({
        targets: elem,
        opacity: 1,
        translateY: ['-20px', 0],
        duration: 1200,
        delay: 1800,
        easing: 'easeOutElastic(1, .4)',
      });
    },
    animateBackgroundButtons() {
      setTimeout(() => {
        anime({
          targets: '.sb_scene_set-bg_btn',
          opacity: 1,
          scale: [0, 1],
          duration: 1200,
          easing: 'easeOutElastic(1, .4)',
          delay: anime.stagger(100, {
            start: 400,
            direction: 'reverse',
          }),
        });
      }, 600);
    },
    animateSceneButtons() {
      anime({
        targets: '.sb_scene_buttons_inner',
        opacity: 1,
        scale: [0, 1],
        duration: 1600,
        delay: 1600,
        easing: 'easeOutElastic(1, .5)',
      });
    },
    animateDisclaimer() {
      anime({
        targets: '.sb_scene_disclaimer',
        opacity: 1,
        duration: 1200,
        delay: 2000,
        easing: 'easeOutQuad',
      });
    },
  },
};
</script>

<style lang="scss">
.error-message {
  position: absolute;
  bottom: 120px;
  width: 100%;
  font-size: 20px;
  color: red;
  text-align: center;
}
.prompt-box {
  margin-left: 20px;
  font-size: 20px;
  // position: absolute;
}
.canvas-container {
  position: absolute !important;
  inset: 0;
  opacity: 0;
  transform-origin: 50% 50%;
}

.sb_scene {
  position: absolute;
  width: 100%;
  height: 100%;

  //
  //  INPUT
  //
  &_input {
    width: 500px;
    position: absolute;
    top: 80px;
    left: 33%;
    z-index: 6;
    padding: 20px;

    textarea {
      width: 500px;
      display: block;
      margin: 0 0 10px 0;
    }
  }

  //
  //  CONTENT
  //

  &_content {
    position: absolute;
    width: 1600px;
    height: 900px;
    z-index: 3;
    left: 50%;
    top: 50%;

    // SHOW SAFE BORDER WITHIN SCENE
    // box-shadow: inset 0px 0px 0px 2px red;
    z-index: 3;
    backface-visibility: hidden;

    @media print {
      position: relative;
      width: inherit;
      height: inherit;
      top: inherit;
      left: inherit;
      transform: none !important;
    }
  }

  //
  //  SET BG
  //

  &_set-bg {
    position: absolute;
    z-index: 6;
    top: 100px;
    right: 15px;

    &_label {
      color: $brand-secondary;
      font-size: 1rem;
      margin: 0 0 20px 0;
      text-align: right;
      opacity: 0;
    }

    &_buttons {
      display: flex;
    }

    &_btn {
      width: 80px;
      height: 80px;
      position: relative;
      margin: 0 0 0 10px;
      opacity: 0;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 3px rgba(#fff, 0.1);
        top: 0;
        left: 0;
        border-radius: 50%;
        transition: box-shadow 0.3s ease;
      }
      > img {
        position: absolute;
        z-index: 2;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
      }

      &:hover {
        &:before {
          box-shadow: 0 0 0 3px rgba(#fff, 0.7);
        }
      }

      &.s_active {
        &:before {
          box-shadow: 0 0 0 3px $brand-secondary;
        }
      }
    }
  }

  //
  //  BUTTONS
  //

  &_buttons {
    position: absolute;
    bottom: 30px;
    left: 50%;
    display: flex;
    transform-origin: 50% 50%;
    transform: translateX(-50%);

    &_inner {
      display: flex;
      opacity: 0;
    }

    &_btn {
      margin: 0 1rem;
    }
  }

  //
  //  DRAG CANVAS
  //

  &_drag-canvas {
    position: absolute;
    top: 180px;
    left: 0;
    width: 1600px;
    height: 600px;
  }

  //
  //  DISCLAIMER
  //
  &_disclaimer {
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    color: $brand-secondary;
    opacity: 0;
  }
}
</style>
