<template>
  <button
    class="sb_button"
    :button-type="buttonType"
    :disabled="disabled"
    :class="{ 'v_icon-only': iconOnly, s_loading: loading }"
    @click="!disabled && $emit('click')"
  >
    <span class="sb_button_wrapper">
      <LoadingSpinner
        v-if="loading"
        :size="'27.2px'"
        :color="
          buttonType === 'primary' || buttonType === 'white'
            ? '#fff'
            : ' #040009'
        "
      />
      <slot v-if="!loading"></slot>
    </span>
  </button>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner';

export default {
  components: {
    LoadingSpinner
  },
  props: {
    /**
     * The type of the button, possible values: 'primary', 'white, 'naked', 'naked-body'
     */
    buttonType: {
      default: 'primary',
      validator: value => ['default'].includes(value)
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * The loading state of the button
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconOnly: false
    };
  },
  mounted() {
    const defaultSlot = this.$slots.default;
    if (defaultSlot.length === 1 && defaultSlot[0].componentOptions) {
      if (defaultSlot[0].componentOptions.tag === 'SvgIcon') {
        this.iconOnly = true;
      }
    }
  }
};
</script>

<style lang="scss">
.sb_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;
  background: none;
  outline: none;
  border-radius: 8px;
  border: none;
  text-align: left;
  transition: opacity 0.3s ease;
  font-size: 32px;
  padding: 12px 32px;
  letter-spacing: 0.5px;
  font-weight: 900;
  background: none;
  color: $brand-white;
  text-transform: uppercase;
  user-select: none;
  text-decoration: none;
  border: solid 3px $brand-white;

  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: color 0.2s ease, border-color 0.2s ease;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    top: 5px;
    left: 5px;
    background: $brand-primary;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover {
    border: solid 3px $brand-white;
    opacity: 1;
    color: white;
    &:before {
      opacity: 1;
    }
  }
  &:focus {
    border: solid 3px $brand-white;
    opacity: 1;
    color: $brand-primary;
    &:hover {
      color: $brand-white;
    }
  }

  &_wrapper {
    position: relative;
    z-index: 2;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .sb_svg-icon {
    transform: scale(1.5);
  }

  //
  //  DISABLED
  //

  &:disabled {
    color: rgba($brand-white, 0.4);
    border: solid 3px rgba($brand-white, 0.2);
    cursor: not-allowed;
    &:hover {
      border: solid 3px rgba($brand-white, 0.2);
      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
