<template>
  <canvas ref="canvasElement" width="1600" height="900"></canvas>
</template>

<script>
import { fabric } from 'fabric';
import axios from 'axios';

let assets;

export default {
  data() {
    return {
      canvas: null,
      basePath: 'images/',
      partObjects: [],
      parts: null,
      bodies: null,
      bodyObjects: null,
      background: null
    };
  },
  async mounted() {
    assets = (
      await axios.get(`/data/${process.env.VUE_APP_SET || 'assets'}.json`)
    ).data;
    this.parts = assets.parts;
    this.bodies = assets.bodies;
    this.canvas = new fabric.Canvas(this.$refs.canvasElement, {
      selection: false
    });
    this.background = await this.createBackground();
    // this.bodyObjects = await this.loadBodies();
    this.partObjects = await this.loadParts();
    this.canvas.renderAll();
  },
  methods: {
    reset() {
      this.canvas.discardActiveObject();
      // this.setBody(0);
      this.partObjects.forEach((part, i) => {
        part.set({
          left: this.parts[i].x,
          top: this.parts[i].y,
          scaleY: 0.15,
          scaleX: 0.15
        });
        part.setCoords();
        part.rotate(0);
      });
      this.background.set({
        fill: '#fff'
      });
      this.$nextTick(() => {
        this.canvas.renderAll();
      });
    },
    saveImage() {
      const image = this.canvas.toDataURL({
        format: 'jpg',
        left: 550,
        top: 230,
        width: 500,
        height: 500
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = 'happy-character.jpg';
      downloadLink.click();
    },
    getImage() {
      return this.canvas.toDataURL({
        format: 'jpg',
        left: 550,
        top: 230,
        width: 500,
        height: 500
      });
    },
    setBackgroundPattern(url, logoType) {
      fabric.util.loadImage(url, img => {
        this.background.set(
          'fill',
          new fabric.Pattern({
            source: img,
            repeat: 'repeat'
          })
        );

        this.canvas.renderAll();
      });
    },
    loadBodies() {
      return Promise.all(
        this.bodies.map(
          ({ image, x, y }, index) =>
            new Promise(resolve => {
              fabric.Image.fromURL(image, img => {
                img.set({
                  visible: index === 0,
                  left: x,
                  top: y,
                  hasControls: false,
                  hasBorders: false,
                  selectable: false
                });
                this.canvas.add(img);
                resolve(img);
              });
            })
        )
      );
    },
    // setBody(index) {
    //   this.bodyObjects.forEach((body, i) => {
    //     body.set({
    //       visible: i === index
    //     });
    //   });
    //   this.canvas.renderAll();
    // },
    createBackground() {
      const shape = new fabric.Rect({
        width: 500,
        height: 500,
        left: 550,
        top: 260,
        fill: '#111111',
        hasControls: false,
        hasBorders: false,
        selectable: false
      });
      this.canvas.add(shape);
      return shape;
    },
    loadParts() {
      return Promise.all(
        this.parts.map(
          part =>
            new Promise(resolve => {
              fabric.Image.fromURL(`${this.basePath}${part.image}`, img => {
                img.set({
                  // generate random points on a circles circumference
                  // name: index,
                  left: part.x,
                  top: part.y,
                  scaleX: 0.15,
                  scaleY: 0.15,
                  originX: 'center',
                  originY: 'center',
                  hasControls: true,
                  hasBorders: true,
                  selectable: true,
                  transparentCorners: false,
                  lockUniScaling: true,
                  borderColor: '#555',
                  cornerColor: '#555',
                  cornerSize: 15
                });
                this.canvas.add(img);
                resolve(img);
              });
            })
        )
      );
    }
  }
};
</script>

<style></style>
